export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _FieldSet: any;
};

/** Expert availability frequency */
export enum AvailabilityFrequency {
  OneTime = 'one_time',
  Weekly = 'weekly'
}

export type BreakTimeSlot = ITimeSlot & {
  __typename?: 'BreakTimeSlot';
  break?: Maybe<ProviderBreak>;
  /** Daylight saving offset between epoch and local timezone in seconds. */
  dst_offset?: Maybe<Scalars['Int']>;
  /** End time in epoch seconds */
  end_time?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  /** Raw offset between epoch and local timezone in seconds. Daylight savings is not accounted */
  raw_offset?: Maybe<Scalars['Int']>;
  /** Start time in epoch seconds */
  start_time?: Maybe<Scalars['Int']>;
  /** ID of the timezone. Example: America/Los_Angeles */
  time_zone_id?: Maybe<Scalars['String']>;
  /** Name of the timezone. Example: Pacific Standard Time */
  time_zone_name?: Maybe<Scalars['String']>;
  type?: Maybe<TimeSlotType>;
};

export type CancellationPolicy = {
  __typename?: 'CancellationPolicy';
  /** The number of minutes before or after the reservation start time when the cancellation fee will be charged. If the value is negative, the fee will be charged before the reservation start time. If the value is positive, the fee will be charged after the reservation start time.  */
  charge_time_offset_min?: Maybe<Scalars['Int']>;
  /** The fixed amount to charge as a cancellation fee. The value is in cents. For example, 100 is $1.00. This value takes precedent over the percentage value. */
  fixed_amount?: Maybe<Scalars['Int']>;
  /** The percentage of the total amount to charge as a cancellation fee. The decimal value must be between 0 and 1. For example, 0.1 is 10% and 0.5 is 50%. */
  percent?: Maybe<Scalars['Float']>;
};

export type CardPaymentMethod = {
  __typename?: 'CardPaymentMethod';
  brand?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

export type ClientProviderServicesFilters = {
  is_add_on?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  provider_id?: InputMaybe<Scalars['String']>;
  service_category?: InputMaybe<ProviderServiceCategory>;
  vehicleId?: InputMaybe<Scalars['String']>;
};

export type ConfirmDeleteAccountInput = {
  code?: InputMaybe<Scalars['String']>;
};

export type ConfirmDeleteAccountResponse = {
  __typename?: 'ConfirmDeleteAccountResponse';
  confirmed: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  amount_off?: Maybe<Scalars['Int']>;
  auto_apply?: Maybe<Scalars['Boolean']>;
  display_properties?: Maybe<CouponDisplayProperties>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  percent_off?: Maybe<Scalars['Float']>;
  restrictions?: Maybe<CouponRestrictions>;
};

export type CouponDisplayProperties = {
  __typename?: 'CouponDisplayProperties';
  description?: Maybe<Scalars['String']>;
  display_location?: Maybe<PromotionCodeDisplayLocation>;
  display_order?: Maybe<Scalars['Float']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Duration of the coupon */
export enum CouponDuration {
  Forever = 'FOREVER',
  Once = 'ONCE',
  Repeating = 'REPEATING'
}

export type CouponRestrictions = {
  __typename?: 'CouponRestrictions';
  apply_to_customer?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['Int']>;
  first_time_transaction?: Maybe<Scalars['Boolean']>;
  max_redemptions?: Maybe<Scalars['Int']>;
  min_vehicle_count?: Maybe<Scalars['Int']>;
  minimum_amount?: Maybe<Scalars['Int']>;
  minimum_amount_currency?: Maybe<Scalars['String']>;
};

export type Discount = {
  __typename?: 'Discount';
  auto_applied?: Maybe<Scalars['Boolean']>;
  coupon?: Maybe<Coupon>;
  id: Scalars['ID'];
  promotion_code?: Maybe<PromotionCode>;
};

export type DiscountAmount = {
  __typename?: 'DiscountAmount';
  amount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Discount>;
  id: Scalars['ID'];
};

export type DiscountApplyInput = {
  promotion_code?: InputMaybe<Scalars['String']>;
};

export type DiscountSelection = {
  __typename?: 'DiscountSelection';
  amount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type Fleet = {
  __typename?: 'Fleet';
  discount_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  owner_uid: Scalars['String'];
};

export type GeoLocationInput = {
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export type GeoLocationResponse = {
  __typename?: 'GeoLocationResponse';
  by_ip?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  subdivision?: Maybe<Scalars['String']>;
  supported?: Maybe<Scalars['Boolean']>;
};

export type IProviderService = {
  active?: Maybe<Scalars['Boolean']>;
  add_on?: Maybe<ProviderServiceAddOn>;
  add_ons?: Maybe<Array<ProviderServiceAddOn>>;
  disabled_at?: Maybe<Scalars['Float']>;
  displayProperties?: Maybe<IProviderServiceDisplayProperties>;
  id: Scalars['ID'];
  input_requirements?: Maybe<Array<InputRequirements>>;
  is_add_on?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<IProviderServiceItem>>;
  options?: Maybe<Array<IProviderServiceOption>>;
  provider_id?: Maybe<Scalars['String']>;
  service_category: ProviderServiceCategory;
  service_type?: Maybe<ProviderServiceType>;
};


export type IProviderServiceAdd_OnArgs = {
  id: Scalars['String'];
};


export type IProviderServiceOptionsArgs = {
  filters?: InputMaybe<ProviderServiceOptionsFilters>;
};

export type IProviderServiceDisplayProperties = {
  additional_info?: Maybe<Scalars['String']>;
  /** Whether to show a none option for the service during customer selection */
  allow_none_option?: Maybe<Scalars['Boolean']>;
  display_order?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  /** Whether or not the user is required to select an option. This will only be honored if the service has more than one option. */
  option_selection_required?: Maybe<Scalars['Boolean']>;
  prompt?: Maybe<Scalars['String']>;
  recommended?: Maybe<Scalars['Boolean']>;
  /** The starting price in cents */
  starting_price?: Maybe<Scalars['Int']>;
  sub_category?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type IProviderServiceItem = {
  display_order?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  included_comment?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  service_category?: Maybe<ProviderServiceCategory>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type IProviderServiceOption = {
  active?: Maybe<Scalars['Boolean']>;
  disabled_at?: Maybe<Scalars['Float']>;
  duration_min?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  /** The price in cents */
  price?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['String']>;
  provider_service_id?: Maybe<Scalars['String']>;
  /** The required size of the vehicle for this option. Applies to vehicle services only. */
  vehicle_size?: Maybe<VehicleSize>;
};

export type IReservationItem = {
  actions?: Maybe<Array<ReservationAction>>;
  completed_at?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  duration_min?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  in_progress_end_time?: Maybe<Scalars['Int']>;
  in_progress_start_time?: Maybe<Scalars['Int']>;
  is_preview?: Maybe<Scalars['Boolean']>;
  provider_services?: Maybe<Array<ProviderServiceSelection>>;
  status?: Maybe<ReservationItemStatus>;
  subtotal?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  vehicle?: Maybe<Vehicle>;
};

export type ITimeSlot = {
  /** Daylight saving offset between epoch and local timezone in seconds. */
  dst_offset?: Maybe<Scalars['Int']>;
  /** End time in epoch seconds */
  end_time?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  /** Raw offset between epoch and local timezone in seconds. Daylight savings is not accounted */
  raw_offset?: Maybe<Scalars['Int']>;
  /** Start time in epoch seconds */
  start_time?: Maybe<Scalars['Int']>;
  /** ID of the timezone. Example: America/Los_Angeles */
  time_zone_id?: Maybe<Scalars['String']>;
  /** Name of the timezone. Example: Pacific Standard Time */
  time_zone_name?: Maybe<Scalars['String']>;
  type?: Maybe<TimeSlotType>;
};

export type InitiateDeleteAccountInput = {
  channel?: InputMaybe<VerificationChannel>;
};

export type InitiateDeleteAccountResponse = {
  __typename?: 'InitiateDeleteAccountResponse';
  channel?: Maybe<VerificationChannel>;
  message: Scalars['String'];
};

/** The type of input requirement */
export enum InputRequirementType {
  Location = 'LOCATION',
  TimeSlot = 'TIME_SLOT',
  Vehicle = 'VEHICLE'
}

export type InputRequirements = {
  __typename?: 'InputRequirements';
  field_name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<InputRequirementType>;
  validation?: Maybe<Array<InputRequirementsValidation>>;
};

export type InputRequirementsValidation = {
  __typename?: 'InputRequirementsValidation';
  error_message?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amount_paid?: Maybe<Scalars['Int']>;
  amount_remaining?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_preview?: Maybe<Scalars['Boolean']>;
  lines?: Maybe<Array<InvoiceItem>>;
  misc_lines?: Maybe<Array<InvoiceMiscItem>>;
  scheduled_payments?: Maybe<Array<ScheduledPayment>>;
  status?: Maybe<InvoiceStatus>;
  /** Total of all subscriptions, invoice items, and prorations on the invoice before any invoice level discount or tax is applied. Item discounts are already incorporated. value in cents */
  subtotal?: Maybe<Scalars['Int']>;
  /** value in cents */
  tax?: Maybe<Scalars['Int']>;
  tax_percent?: Maybe<Scalars['Float']>;
  /** value in cents */
  total?: Maybe<Scalars['Int']>;
  total_discount_amounts?: Maybe<Array<DiscountAmount>>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type InvoiceMiscItem = {
  __typename?: 'InvoiceMiscItem';
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  type?: Maybe<MiscellaneousItemType>;
};

/** Status of the invoice */
export enum InvoiceStatus {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  Uncollectible = 'UNCOLLECTIBLE',
  Void = 'VOID'
}

/** Miscellaneous item type */
export enum MiscellaneousItemType {
  CancellationFee = 'CANCELLATION_FEE',
  Gratuity = 'GRATUITY',
  Other = 'OTHER'
}

export type Mutation = {
  __typename?: 'Mutation';
  reservationAddGratuity?: Maybe<Reservation>;
  reservationApplyDiscount?: Maybe<Reservation>;
  reservationCancel?: Maybe<Reservation>;
  reservationConfirmItems?: Maybe<ReservationConfirmResponse>;
  reservationCreate?: Maybe<Reservation>;
  reservationItemCreate?: Maybe<Reservation>;
  reservationItemDelete?: Maybe<Reservation>;
  reservationItemUpdate?: Maybe<Reservation>;
  reservationSchedule?: Maybe<ReservationScheduleResponse>;
  reservationUpdate?: Maybe<Reservation>;
  userConfirmDeleteAccount: ConfirmDeleteAccountResponse;
  userInitiateDeleteAccount: InitiateDeleteAccountResponse;
  userLocationSave?: Maybe<UserLocation>;
  vehicleDelete: VehicleDeleteResponse;
  vehicleUpdate: Vehicle;
};


export type MutationReservationAddGratuityArgs = {
  id: Scalars['String'];
  input: ReservationAddGratuityInput;
};


export type MutationReservationApplyDiscountArgs = {
  id: Scalars['String'];
  input: DiscountApplyInput;
};


export type MutationReservationCancelArgs = {
  id: Scalars['String'];
};


export type MutationReservationConfirmItemsArgs = {
  id: Scalars['String'];
};


export type MutationReservationCreateArgs = {
  input: ReservationCreateInput;
};


export type MutationReservationItemCreateArgs = {
  id: Scalars['String'];
  input: ReservationItemSelectionInput;
};


export type MutationReservationItemDeleteArgs = {
  id: Scalars['String'];
  itemId: Scalars['String'];
};


export type MutationReservationItemUpdateArgs = {
  id: Scalars['String'];
  input: ReservationItemSelectionInput;
  itemId: Scalars['String'];
};


export type MutationReservationScheduleArgs = {
  id: Scalars['String'];
  input: ReservationScheduleInput;
};


export type MutationReservationUpdateArgs = {
  id: Scalars['String'];
  input: ReservationUpdateInput;
};


export type MutationUserConfirmDeleteAccountArgs = {
  data: ConfirmDeleteAccountInput;
};


export type MutationUserInitiateDeleteAccountArgs = {
  data: InitiateDeleteAccountInput;
};


export type MutationUserLocationSaveArgs = {
  location: SaveUserLocationInput;
};


export type MutationVehicleDeleteArgs = {
  id: Scalars['String'];
};


export type MutationVehicleUpdateArgs = {
  id: Scalars['String'];
  input: VehicleUpdateInput;
};

export type PaymentChargePolicy = {
  __typename?: 'PaymentChargePolicy';
  /** The payment methods that are accepted by your business. If this is not set, all eligible payment methods will be accepted. */
  accepted_payment_methods?: Maybe<Array<PaymentMethodType>>;
  /** The status of the reservation when the remaining amount will be charged or is due. If this is not set, the remaining amount will be charged or is due when the reservation is completed. */
  remaining_amount_when_status?: Maybe<ReservationStatus>;
  /** The amount to charge as a pre-charge. If this is set with pre_charge_percent, the higher of the two values will be used. If this value is greater than the total amount due, the total amount will be used. The value is in cents. For example, 100 is $1.00. */
  upfront_charge_amount?: Maybe<Scalars['Float']>;
  /** The percentage of the total amount to charge as a pre-charge. The decimal value must be between 0 and 1. For example, 0.1 is 10% and 0.5 is 50%. */
  upfront_charge_percent?: Maybe<Scalars['Float']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  card?: Maybe<CardPaymentMethod>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentMethodType>;
};

/** The type of payment method */
export enum PaymentMethodType {
  Card = 'CARD',
  Cash = 'CASH'
}

export type PaymentTransaction = {
  __typename?: 'PaymentTransaction';
  amount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  payment_method_type?: Maybe<PaymentMethodType>;
  status?: Maybe<PaymentTransactionStatus>;
  transaction_type?: Maybe<PaymentTransactionType>;
};

/** The status of the payment transaction */
export enum PaymentTransactionStatus {
  Finalized = 'FINALIZED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED'
}

/** The type of the payment transaction */
export enum PaymentTransactionType {
  Charge = 'CHARGE',
  Refund = 'REFUND',
  Transfer = 'TRANSFER',
  TransferReversal = 'TRANSFER_REVERSAL'
}

export type PromotionCode = {
  __typename?: 'PromotionCode';
  code?: Maybe<Scalars['String']>;
  coupon?: Maybe<Coupon>;
  id: Scalars['ID'];
  restrictions?: Maybe<PromotionCodeRestrictions>;
};

/** Location where the promotion code will be displayed */
export enum PromotionCodeDisplayLocation {
  HomePage = 'HOME_PAGE',
  None = 'NONE'
}

export type PromotionCodeRestrictions = {
  __typename?: 'PromotionCodeRestrictions';
  apply_to_customer?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['Int']>;
  first_time_transaction?: Maybe<Scalars['Boolean']>;
  max_redemptions?: Maybe<Scalars['Int']>;
  min_vehicle_count?: Maybe<Scalars['Int']>;
  minimum_amount?: Maybe<Scalars['Int']>;
  minimum_amount_currency?: Maybe<Scalars['String']>;
};

export type Provider = {
  __typename?: 'Provider';
  /** A list of active promotions that the user can use. */
  active_promotions?: Maybe<Array<PromotionCode>>;
  id: Scalars['ID'];
  policies?: Maybe<ProviderPolicies>;
  public_profile?: Maybe<ProviderPublicProfile>;
};

export type ProviderAccountSession = {
  __typename?: 'ProviderAccountSession';
  client_secret?: Maybe<Scalars['String']>;
};

export type ProviderAvailabilitiesGetFilters = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  provider_id?: InputMaybe<Scalars['String']>;
  radius_meters?: InputMaybe<Scalars['Float']>;
};

export type ProviderAvailability = {
  __typename?: 'ProviderAvailability';
  available_time_slots?: Maybe<Array<TimeSlot>>;
  id: Scalars['ID'];
  /** Whether the availability can be reserved by any customer or only the provider's customers */
  public?: Maybe<Scalars['Boolean']>;
  reservation_window?: Maybe<TimeSlot>;
};


export type ProviderAvailabilityAvailable_Time_SlotsArgs = {
  filters?: InputMaybe<ProviderAvailabilityTimeSlotsFilter>;
};

export type ProviderAvailabilityTimeSlotsFilter = {
  duration_min: Scalars['Int'];
};

export type ProviderBreak = {
  __typename?: 'ProviderBreak';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ProviderBusinessSettings = {
  __typename?: 'ProviderBusinessSettings';
  /** An ISO 3166-1 alpha-2 country code */
  country?: Maybe<Scalars['String']>;
  /** The category of the services offered */
  services_category?: Maybe<ProviderServiceCategory>;
};

export type ProviderLocale = {
  __typename?: 'ProviderLocale';
  currency?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type ProviderLocation = {
  __typename?: 'ProviderLocation';
  address?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  radius_meters?: Maybe<Scalars['Float']>;
};

export type ProviderPaymentAccount = {
  __typename?: 'ProviderPaymentAccount';
  charges_enabled?: Maybe<Scalars['Boolean']>;
  details_submitted?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  payouts_enabled?: Maybe<Scalars['Boolean']>;
  requirements?: Maybe<ProviderPaymentAccountRequirements>;
};

export type ProviderPaymentAccountRequirements = {
  __typename?: 'ProviderPaymentAccountRequirements';
  currently_due?: Maybe<Array<Scalars['String']>>;
  eventually_due?: Maybe<Array<Scalars['String']>>;
  past_due?: Maybe<Array<Scalars['String']>>;
};

export type ProviderPolicies = {
  __typename?: 'ProviderPolicies';
  /** The list of cancellation policies for the provider. The first policy to match the cancellation time will be used. */
  cancellation_policies?: Maybe<Array<CancellationPolicy>>;
  payment_charge_policy?: Maybe<PaymentChargePolicy>;
  reservation_requirements?: Maybe<ProviderReservationRequirements>;
};

export type ProviderPricing = {
  __typename?: 'ProviderPricing';
  monthly_fee?: Maybe<Scalars['Float']>;
  transaction_fee_pricing?: Maybe<ProviderTransactionFeePricing>;
};

export type ProviderPublicProfile = {
  __typename?: 'ProviderPublicProfile';
  banner_image_url?: Maybe<Scalars['String']>;
  display_bio?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  locale?: Maybe<ProviderLocale>;
  logo_image_url?: Maybe<Scalars['String']>;
  personal_image_url?: Maybe<Scalars['String']>;
  show_display_name_by_logo?: Maybe<Scalars['Boolean']>;
  show_services_comparison?: Maybe<Scalars['Boolean']>;
  url_slug?: Maybe<Scalars['String']>;
};

export type ProviderReservationRequirements = {
  __typename?: 'ProviderReservationRequirements';
  requires_access_to_power?: Maybe<Scalars['Boolean']>;
  requires_access_to_water?: Maybe<Scalars['Boolean']>;
};

export type ProviderService = IProviderService & {
  __typename?: 'ProviderService';
  active?: Maybe<Scalars['Boolean']>;
  add_on?: Maybe<ProviderServiceAddOn>;
  add_ons?: Maybe<Array<ProviderServiceAddOn>>;
  disabled_at?: Maybe<Scalars['Float']>;
  displayProperties?: Maybe<IProviderServiceDisplayProperties>;
  id: Scalars['ID'];
  input_requirements?: Maybe<Array<InputRequirements>>;
  is_add_on?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<IProviderServiceItem>>;
  options?: Maybe<Array<IProviderServiceOption>>;
  provider_id?: Maybe<Scalars['String']>;
  service_category: ProviderServiceCategory;
  service_type?: Maybe<ProviderServiceType>;
};


export type ProviderServiceAdd_OnArgs = {
  id: Scalars['String'];
};


export type ProviderServiceOptionsArgs = {
  filters?: InputMaybe<ProviderServiceOptionsFilters>;
};

export type ProviderServiceAddOn = {
  __typename?: 'ProviderServiceAddOn';
  id: Scalars['ID'];
  included?: Maybe<Scalars['Boolean']>;
  included_options?: Maybe<Array<IProviderServiceOption>>;
  service?: Maybe<IProviderService>;
};

/** Category type for provider service */
export enum ProviderServiceCategory {
  MaidServices = 'MAID_SERVICES',
  VehicleWash = 'VEHICLE_WASH'
}

export type ProviderServiceDisplayProperties = IProviderServiceDisplayProperties & {
  __typename?: 'ProviderServiceDisplayProperties';
  additional_info?: Maybe<Scalars['String']>;
  /** Whether to show a none option for the service during customer selection */
  allow_none_option?: Maybe<Scalars['Boolean']>;
  display_order?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  /** Whether or not the user is required to select an option. This will only be honored if the service has more than one option. */
  option_selection_required?: Maybe<Scalars['Boolean']>;
  prompt?: Maybe<Scalars['String']>;
  recommended?: Maybe<Scalars['Boolean']>;
  /** The starting price in cents */
  starting_price?: Maybe<Scalars['Int']>;
  sub_category?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ProviderServiceItem = IProviderServiceItem & {
  __typename?: 'ProviderServiceItem';
  display_order?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  included_comment?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  service_category?: Maybe<ProviderServiceCategory>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ProviderServiceOption = IProviderServiceOption & {
  __typename?: 'ProviderServiceOption';
  active?: Maybe<Scalars['Boolean']>;
  disabled_at?: Maybe<Scalars['Float']>;
  duration_min?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  /** The price in cents */
  price?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['String']>;
  provider_service_id?: Maybe<Scalars['String']>;
  /** The required size of the vehicle for this option. Applies to vehicle services only. */
  vehicle_size?: Maybe<VehicleSize>;
};

export type ProviderServiceOptionsFilters = {
  /** whether to include inactive options. can be used for experts and operators */
  active?: InputMaybe<Scalars['Boolean']>;
};

export type ProviderServiceSelection = {
  __typename?: 'ProviderServiceSelection';
  duration_min?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  option?: Maybe<ProviderServiceOption>;
  price?: Maybe<Scalars['Float']>;
  service?: Maybe<ProviderService>;
};

export type ProviderServiceSelectionInput = {
  option_id: Scalars['String'];
  service_id: Scalars['String'];
};

/** Type of service */
export enum ProviderServiceType {
  Mobile = 'MOBILE'
}

export type ProviderStripeInfo = {
  __typename?: 'ProviderStripeInfo';
  account?: Maybe<ProviderPaymentAccount>;
  account_session?: Maybe<ProviderAccountSession>;
};

export type ProviderTransactionFeePricing = {
  __typename?: 'ProviderTransactionFeePricing';
  new_customer_lead_fee_percent?: Maybe<Scalars['Float']>;
  transaction_fee_base?: Maybe<Scalars['Float']>;
  transaction_fee_percent?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  activeProviderHasSlug?: Maybe<Scalars['Boolean']>;
  activeProviderId?: Maybe<Scalars['String']>;
  activeProviderSlug?: Maybe<Scalars['String']>;
  clientPaymentMethods?: Maybe<Array<PaymentMethod>>;
  deviceLocation?: Maybe<UserLocation>;
  geoLocation?: Maybe<GeoLocationResponse>;
  homePageSelectedVehicle?: Maybe<Vehicle>;
  me?: Maybe<User>;
  orderLocation?: Maybe<UserLocation>;
  orderTimeSlot?: Maybe<TimeSlot>;
  provider?: Maybe<Provider>;
  providerAvailabilityNearby?: Maybe<Array<ProviderAvailability>>;
  providerBySlug?: Maybe<Provider>;
  providerService?: Maybe<IProviderService>;
  providerServices?: Maybe<Array<IProviderService>>;
  reservation?: Maybe<Reservation>;
  reservationGetByChannel?: Maybe<Reservation>;
  reservations?: Maybe<Array<Reservation>>;
  selectedPaymentMethodId?: Maybe<Scalars['String']>;
  userLocation?: Maybe<UserLocation>;
  userLocations?: Maybe<Array<UserLocation>>;
  vehicleSelectionMakes: Array<VehicleMakeSelectionEntry>;
  vehicleSelectionModels: Array<VehicleModelSelectionEntry>;
  vehicleSelectionTrims: Array<VehicleTrimSelectionEntry>;
  vehicleSelectionYears: Array<VehicleYearSelectionEntry>;
  /** @deprecated use providerServices instead */
  vehicleWashPackages?: Maybe<Array<VehicleWashPackage>>;
  vehicles?: Maybe<Array<Vehicle>>;
};


export type QueryGeoLocationArgs = {
  input?: InputMaybe<GeoLocationInput>;
};


export type QueryProviderArgs = {
  id: Scalars['String'];
};


export type QueryProviderAvailabilityNearbyArgs = {
  filters: ProviderAvailabilitiesGetFilters;
};


export type QueryProviderBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryProviderServiceArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryProviderServicesArgs = {
  filters?: InputMaybe<ClientProviderServicesFilters>;
};


export type QueryReservationArgs = {
  id: Scalars['String'];
};


export type QueryReservationGetByChannelArgs = {
  channel_id: Scalars['String'];
};


export type QueryReservationsArgs = {
  filters?: InputMaybe<ReservationsFilter>;
};


export type QueryUserLocationArgs = {
  id: Scalars['String'];
};


export type QueryVehicleSelectionMakesArgs = {
  year: Scalars['String'];
};


export type QueryVehicleSelectionModelsArgs = {
  make: Scalars['String'];
  year: Scalars['String'];
};


export type QueryVehicleSelectionTrimsArgs = {
  make: Scalars['String'];
  model: Scalars['String'];
  year: Scalars['String'];
};


export type QueryVehicleWashPackagesArgs = {
  filters?: InputMaybe<VehicleWashPackagesFilterInput>;
};

export type Reservation = {
  __typename?: 'Reservation';
  accepted_payment_methods?: Maybe<ReservationPaymentMethods>;
  availability_id?: Maybe<Scalars['ID']>;
  cancellation?: Maybe<ReservationCancellationDetails>;
  client?: Maybe<User>;
  completed_at?: Maybe<Scalars['Int']>;
  confirmation_code?: Maybe<Scalars['String']>;
  discount_selections?: Maybe<Array<DiscountSelection>>;
  duration_min?: Maybe<Scalars['Int']>;
  expert_first_name?: Maybe<Scalars['String']>;
  expert_id?: Maybe<Scalars['String']>;
  finalized_at?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  in_progress_estimated_end_time?: Maybe<Scalars['Int']>;
  in_progress_start_time?: Maybe<Scalars['Int']>;
  input_requirements?: Maybe<Array<InputRequirements>>;
  invoice?: Maybe<Invoice>;
  is_preview?: Maybe<Scalars['Boolean']>;
  is_stationary?: Maybe<Scalars['Boolean']>;
  is_weekly?: Maybe<Scalars['Boolean']>;
  item?: Maybe<ReservationItem>;
  items?: Maybe<Array<IReservationItem>>;
  location?: Maybe<UserLocation>;
  payment_transactions?: Maybe<Array<PaymentTransaction>>;
  preview?: Maybe<Reservation>;
  provider_availabilities?: Maybe<Array<ProviderAvailability>>;
  provider_id?: Maybe<Scalars['String']>;
  service_category?: Maybe<ProviderServiceCategory>;
  status?: Maybe<ReservationStatus>;
  subtotal?: Maybe<Scalars['Int']>;
  /** The time slot of the reservation. If a value was not provided at creation, the start/end times will be set to 0. */
  time_slot?: Maybe<TimeSlot>;
  user_first_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};


export type ReservationItemArgs = {
  id: Scalars['String'];
};


export type ReservationProvider_AvailabilitiesArgs = {
  input?: InputMaybe<ReservationProviderAvailabilitiesGetInput>;
};

export type ReservationAction = {
  __typename?: 'ReservationAction';
  disabled?: Maybe<Scalars['Boolean']>;
  disabled_message?: Maybe<Scalars['String']>;
  type?: Maybe<ReservationActionType>;
};

export enum ReservationActionType {
  CompleteItem = 'COMPLETE_ITEM',
  Confirm = 'CONFIRM',
  Decline = 'DECLINE',
  Reschedule = 'RESCHEDULE',
  StartItem = 'START_ITEM',
  StartRoute = 'START_ROUTE'
}

export type ReservationAddGratuityInput = {
  /** The amount to add as gratuity in cents. Defaults to 0. If an existing amount was added to the reservation, the amount will be updated to the passed value. The card will be charged or refunded depending on the new amount. Refunding amounts will be capped at 2 hours after the reservation's completion time. */
  amount?: InputMaybe<Scalars['Int']>;
};

export type ReservationCancellationDetails = {
  __typename?: 'ReservationCancellationDetails';
  can_cancel?: Maybe<Scalars['Boolean']>;
  cancellation_fee?: Maybe<Scalars['Int']>;
};

export type ReservationConfirmResponse = {
  __typename?: 'ReservationConfirmResponse';
  reservation?: Maybe<Reservation>;
};

export type ReservationCreateInput = {
  expert_id?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<ReservationItemSelectionInput>>;
  location_id?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['String']>;
  time_slot?: InputMaybe<StartTimeFutureTimeSlotInput>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type ReservationItem = IReservationItem & {
  __typename?: 'ReservationItem';
  actions?: Maybe<Array<ReservationAction>>;
  completed_at?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  duration_min?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  in_progress_end_time?: Maybe<Scalars['Int']>;
  in_progress_start_time?: Maybe<Scalars['Int']>;
  is_preview?: Maybe<Scalars['Boolean']>;
  provider_services?: Maybe<Array<ProviderServiceSelection>>;
  status?: Maybe<ReservationItemStatus>;
  subtotal?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  vehicle?: Maybe<Vehicle>;
};

export type ReservationItemSelectionInput = {
  /** The services and options to be applied to the reservation item. If the array is emp provided and id matches an existing item, the existing item's services and options will be used. */
  provider_service_selections?: InputMaybe<Array<ProviderServiceSelectionInput>>;
  vehicle_id?: InputMaybe<Scalars['String']>;
};

/** Reservation item status */
export enum ReservationItemStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export type ReservationPaymentMethodInput = {
  payment_method_id?: InputMaybe<Scalars['String']>;
  type: PaymentMethodType;
};

export type ReservationPaymentMethods = {
  __typename?: 'ReservationPaymentMethods';
  methods?: Maybe<Array<PaymentMethod>>;
  types?: Maybe<Array<PaymentMethodType>>;
};

export type ReservationProviderAvailabilitiesGetInput = {
  /** The location of the user. If not provided, the location of the reservation will be used. If not provided, the location based on ip. */
  location?: InputMaybe<GeoLocationInput>;
  /** The provider id to search for availabilities. If not provided, the provider id of the reservation will be used. */
  provider_id?: InputMaybe<Scalars['String']>;
  /** The radius in meters to search for providers. Defaults to 30 miles up to 50 meters will be used, while prioritizing closer availabilities. */
  radius_meters?: InputMaybe<Scalars['Float']>;
  /** The start time of the query in seconds since epoch. If not provider, the reservation's start time will be used or the current time if the reservation is not provided. */
  time_slot_start_time?: InputMaybe<Scalars['Int']>;
};

export type ReservationScheduleInput = {
  payment_method?: InputMaybe<ReservationPaymentMethodInput>;
};

export type ReservationScheduleResponse = {
  __typename?: 'ReservationScheduleResponse';
  reservation?: Maybe<Reservation>;
};

/** Reservation status */
export enum ReservationStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Draft = 'DRAFT',
  EnRoute = 'EN_ROUTE',
  Finalized = 'FINALIZED',
  InProgress = 'IN_PROGRESS',
  Scheduled = 'SCHEDULED',
  Unassigned = 'UNASSIGNED',
  Unscheduled = 'UNSCHEDULED'
}

export enum ReservationStatusCategory {
  Draft = 'DRAFT',
  Past = 'PAST',
  Upcoming = 'UPCOMING'
}

export type ReservationTimeSlot = ITimeSlot & {
  __typename?: 'ReservationTimeSlot';
  /** Daylight saving offset between epoch and local timezone in seconds. */
  dst_offset?: Maybe<Scalars['Int']>;
  /** End time in epoch seconds */
  end_time?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  /** Raw offset between epoch and local timezone in seconds. Daylight savings is not accounted */
  raw_offset?: Maybe<Scalars['Int']>;
  /** Start time in epoch seconds */
  start_time?: Maybe<Scalars['Int']>;
  /** ID of the timezone. Example: America/Los_Angeles */
  time_zone_id?: Maybe<Scalars['String']>;
  /** Name of the timezone. Example: Pacific Standard Time */
  time_zone_name?: Maybe<Scalars['String']>;
  type?: Maybe<TimeSlotType>;
};

export type ReservationUpdateInput = {
  location_id?: InputMaybe<Scalars['String']>;
  time_slot?: InputMaybe<StartTimeFutureTimeSlotInput>;
};

export type ReservationsFilter = {
  last_start_time?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort_direction?: InputMaybe<SortDirection>;
  status_category?: InputMaybe<ReservationStatusCategory>;
};

export type SaveUserLocationInput = {
  access_to_power?: InputMaybe<Scalars['Boolean']>;
  access_to_water?: InputMaybe<Scalars['Boolean']>;
  additional_instructions?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location_type?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ScheduledPayment = {
  __typename?: 'ScheduledPayment';
  /** The amount due from the reservation's total amount. Value in cents. */
  amount_due?: Maybe<Scalars['Int']>;
  /** The epoch time in seconds when the amount is due. 0 if the invoice is not due. -1 if the invoice is due immediately. */
  amount_due_at?: Maybe<Scalars['Int']>;
  /** The status of the reservation when the payment is due. */
  amount_due_when_status?: Maybe<ReservationStatus>;
  id: Scalars['ID'];
};

/** How to sort the results */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StartTimeFutureTimeSlotInput = {
  /** Daylight saving offset between epoch and local timezone in seconds. */
  dst_offset: Scalars['Int'];
  /** Raw offset between epoch and local timezone in seconds. Daylight savings is not accounted */
  raw_offset: Scalars['Int'];
  /** Start time in epoch seconds */
  start_time: Scalars['Int'];
  /** ID of the timezone. Example: America/Los_Angeles */
  time_zone_id: Scalars['String'];
  /** Name of the timezone. Example: Pacific Standard Time */
  time_zone_name?: InputMaybe<Scalars['String']>;
};

export type TimeSlot = ITimeSlot & {
  __typename?: 'TimeSlot';
  /** Daylight saving offset between epoch and local timezone in seconds. */
  dst_offset?: Maybe<Scalars['Int']>;
  /** End time in epoch seconds */
  end_time?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  /** Raw offset between epoch and local timezone in seconds. Daylight savings is not accounted */
  raw_offset?: Maybe<Scalars['Int']>;
  /** Start time in epoch seconds */
  start_time?: Maybe<Scalars['Int']>;
  /** ID of the timezone. Example: America/Los_Angeles */
  time_zone_id?: Maybe<Scalars['String']>;
  /** Name of the timezone. Example: Pacific Standard Time */
  time_zone_name?: Maybe<Scalars['String']>;
  type?: Maybe<TimeSlotType>;
};

/** Time slot type */
export enum TimeSlotType {
  Break = 'BREAK',
  Reservation = 'RESERVATION'
}

export type User = {
  __typename?: 'User';
  country?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fleet?: Maybe<Fleet>;
  id: Scalars['ID'];
  is_anonymous?: Maybe<Scalars['Boolean']>;
  is_expert?: Maybe<Scalars['Boolean']>;
  is_operator?: Maybe<Scalars['Boolean']>;
  phone_number?: Maybe<Scalars['String']>;
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  twilio_user_id?: Maybe<Scalars['String']>;
  /** @deprecated Use id instead. */
  uid: Scalars['ID'];
  verified?: Maybe<Scalars['Boolean']>;
};

export type UserLocation = {
  __typename?: 'UserLocation';
  access_to_power?: Maybe<Scalars['Boolean']>;
  access_to_water?: Maybe<Scalars['Boolean']>;
  additional_instructions?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  gplace_id?: Maybe<Scalars['String']>;
  gplaces_photo_reference?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  latitude?: Maybe<Scalars['Float']>;
  location_type?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['ID']>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  license_plate: Scalars['String'];
  license_plate_state: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  size: VehicleSize;
  trim?: Maybe<Scalars['String']>;
  /** User's ID */
  uid: Scalars['ID'];
  year: Scalars['String'];
};

export type VehicleDeleteResponse = {
  __typename?: 'VehicleDeleteResponse';
  deleted: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type VehicleMakeSelectionEntry = {
  __typename?: 'VehicleMakeSelectionEntry';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type VehicleModelSelectionEntry = {
  __typename?: 'VehicleModelSelectionEntry';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Size of the vehicle */
export enum VehicleSize {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  Unknown = 'UNKNOWN',
  XLarge = 'X_LARGE'
}

export type VehicleTrimSelectionEntry = {
  __typename?: 'VehicleTrimSelectionEntry';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Type of the vehicle */
export enum VehicleType {
  Boat = 'BOAT',
  Car = 'CAR',
  Motorcycle = 'MOTORCYCLE'
}

export type VehicleUpdateInput = {
  color?: InputMaybe<Scalars['String']>;
  license_plate?: InputMaybe<Scalars['String']>;
  license_plate_state?: InputMaybe<Scalars['String']>;
};

export type VehicleWashAddOn = {
  __typename?: 'VehicleWashAddOn';
  displayProperties?: Maybe<VehicleWashAddOnDisplayProperties>;
  id: Scalars['ID'];
  /** @deprecated identifier will be deprecated */
  identifier: VehicleWashAddonIdentifier;
  name: Scalars['String'];
  plans?: Maybe<Array<VehicleWashAddOnPlan>>;
};

/** Vehicle add-on dirt level */
export enum VehicleWashAddOnDirtLevel {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  Na = 'NA',
  None = 'NONE'
}

export type VehicleWashAddOnDisplayProperties = IProviderServiceDisplayProperties & {
  __typename?: 'VehicleWashAddOnDisplayProperties';
  additional_info?: Maybe<Scalars['String']>;
  /** Whether to show a none option for the service during customer selection */
  allow_none_option?: Maybe<Scalars['Boolean']>;
  /** @deprecated use subtitle instead */
  description?: Maybe<Scalars['String']>;
  display_order?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  /** Whether or not the user is required to select an option. This will only be honored if the service has more than one option. */
  option_selection_required?: Maybe<Scalars['Boolean']>;
  prompt?: Maybe<Scalars['String']>;
  recommended?: Maybe<Scalars['Boolean']>;
  /** The starting price in cents */
  starting_price?: Maybe<Scalars['Int']>;
  sub_category?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type VehicleWashAddOnItem = {
  __typename?: 'VehicleWashAddOnItem';
  dirty_level: VehicleWashAddOnDirtLevel;
  identifier: VehicleWashAddonIdentifier;
};

export type VehicleWashAddOnPlan = {
  __typename?: 'VehicleWashAddOnPlan';
  dirty_level: VehicleWashAddOnDirtLevel;
  duration_min: Scalars['Int'];
  /** The price in cents */
  price: Scalars['Int'];
  vehicle_size: VehicleSize;
};

/** Identifier for wash add-ons */
export enum VehicleWashAddonIdentifier {
  CeramicMaintenance = 'CERAMIC_MAINTENANCE',
  ChildSeat = 'CHILD_SEAT',
  ClayBar = 'CLAY_BAR',
  CleanLeatherSeats = 'CLEAN_LEATHER_SEATS',
  HeadlightRestoration = 'HEADLIGHT_RESTORATION',
  PetHairRemoval = 'PET_HAIR_REMOVAL',
  PlasticVinylRestorer = 'PLASTIC_VINYL_RESTORER',
  StainRemoval = 'STAIN_REMOVAL',
  WindshieldWaterRepellent = 'WINDSHIELD_WATER_REPELLENT'
}

export type VehicleWashPackage = {
  __typename?: 'VehicleWashPackage';
  addOns?: Maybe<Array<VehicleWashAddOn>>;
  category?: Maybe<VehicleWashPackageCategory>;
  displayProperties?: Maybe<VehicleWashPackageDisplayProperties>;
  id: Scalars['ID'];
  /** @deprecated identifier will be deprecated */
  identifier: VehicleWashPackageIdentifier;
  items?: Maybe<Array<VehicleWashPackageItem>>;
  name: Scalars['String'];
  plans?: Maybe<Array<VehicleWashPackagePlan>>;
  supported_add_ons?: Maybe<Array<VehicleWashAddonIdentifier>>;
  vehicleSize?: Maybe<VehicleSize>;
  vehicle_type?: Maybe<VehicleType>;
};

/** Category type of wash package */
export enum VehicleWashPackageCategory {
  CarDetail = 'CAR_DETAIL',
  CarWash = 'CAR_WASH'
}

/** Dirt level for wash packages */
export enum VehicleWashPackageDirtLevel {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type VehicleWashPackageDisplayProperties = IProviderServiceDisplayProperties & {
  __typename?: 'VehicleWashPackageDisplayProperties';
  additional_info?: Maybe<Scalars['String']>;
  /** Whether to show a none option for the service during customer selection */
  allow_none_option?: Maybe<Scalars['Boolean']>;
  display_order?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  included_add_ons?: Maybe<Array<VehicleWashAddOnItem>>;
  notes: Array<Scalars['String']>;
  /** Whether or not the user is required to select an option. This will only be honored if the service has more than one option. */
  option_selection_required?: Maybe<Scalars['Boolean']>;
  prompt?: Maybe<Scalars['String']>;
  recommended?: Maybe<Scalars['Boolean']>;
  /** The starting price in cents */
  starting_price?: Maybe<Scalars['Int']>;
  sub_category?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  theme_color?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Identifier for wash package */
export enum VehicleWashPackageIdentifier {
  Glossy = 'GLOSSY',
  InsideOnly = 'INSIDE_ONLY',
  OutsideOnly = 'OUTSIDE_ONLY',
  Platinum = 'PLATINUM',
  Ultimate = 'ULTIMATE',
  Waxed = 'WAXED'
}

export type VehicleWashPackageItem = IProviderServiceItem & {
  __typename?: 'VehicleWashPackageItem';
  display_order?: Maybe<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  included_comment?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  service_category?: Maybe<ProviderServiceCategory>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type VehicleWashPackagePlan = {
  __typename?: 'VehicleWashPackagePlan';
  dirty_level: VehicleWashPackageDirtLevel;
  duration_min: Scalars['Int'];
  /** The price in cents */
  price: Scalars['Int'];
  vehicle_size: VehicleSize;
};

export type VehicleWashPackagesFilterInput = {
  is_add_on?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  provider_id?: InputMaybe<Scalars['String']>;
  /** This value will always default to VEHICLE_WASH */
  service_category?: InputMaybe<ProviderServiceCategory>;
  vehicleId?: InputMaybe<Scalars['String']>;
};

export type VehicleYearSelectionEntry = {
  __typename?: 'VehicleYearSelectionEntry';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** The verification channels currently supported */
export enum VerificationChannel {
  Call = 'call',
  Email = 'email',
  Sms = 'sms',
  Sna = 'sna',
  Whatsapp = 'whatsapp'
}
