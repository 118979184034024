<ng-container *ngIf="{provider: provider$ | async } as state">
    <ion-header class="global-desktop-header" part="header" *ngIf="{publicProfile: state?.provider?.data?.providerBySlug?.public_profile} as headerState">
        <app-top-navbar
                *ngIf="showPageHeader"
                [logoText]="headerState?.publicProfile?.display_name"
                [logoImageUrl]="headerState?.publicProfile?.logo_image_url"
                [hideLogoText]="headerState?.publicProfile?.show_display_name_by_logo === false"
                [hideLogoOnPhone]="false"
                [showBackButton]="false"
                [defaultBackRoute]="backRoute"
                [class.hidden-on-phone]="headerHiddenOnScroll"
                (loginButtonClicked)="openLoginPane()"
        ></app-top-navbar>
    </ion-header>

    <ion-content scroll-events="true" (ionScroll)="onScroll($event)">
        <div class="gb-page horizontal-padding" *ngIf="{showToolbar: showBackButton || toolbarTemplate} as state">
            <ion-toolbar class="gb-top-toolbar" *ngIf="state.showToolbar">
                <ion-back-button
                        *ngIf="showBackButton"
                        slot="start"
                        [defaultHref]="backRoute"
                        color="dark"></ion-back-button>
                <ng-container
                        *ngIf="toolbarTemplate"
                        [ngTemplateOutlet]="toolbarTemplate"></ng-container>
            </ion-toolbar>
            <div class="content"
                 [class.toolbar-padding]="state.showToolbar"
                 [class.ion-padding-top]="!state.showToolbar">
                <ng-content></ng-content>
            </div>
        </div>
    </ion-content>

</ng-container>
