import * as Types from './generated/graphql-schema';

import { gql } from 'apollo-angular';
export type InputRequirementsPropsFragment = { __typename?: 'InputRequirements', field_name?: string | null, label?: string | null, type?: Types.InputRequirementType | null, validation?: Array<{ __typename?: 'InputRequirementsValidation', error_message?: string | null, required?: boolean | null }> | null };

export const InputRequirementsPropsFragmentDoc = gql`
    fragment InputRequirementsProps on InputRequirements {
  field_name
  label
  type
  validation {
    error_message
    required
  }
}
    `;