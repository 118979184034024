import * as Types from '../generated/graphql-schema';

import { gql } from 'apollo-angular';
export type PaymentMethodPropsFragment = { __typename?: 'PaymentMethod', id: string, type?: Types.PaymentMethodType | null, label?: string | null, card?: { __typename?: 'CardPaymentMethod', brand?: string | null, last4?: string | null } | null };

export type ReservationPaymentMethodsPropsFragment = { __typename?: 'ReservationPaymentMethods', types?: Array<Types.PaymentMethodType> | null, methods?: Array<{ __typename?: 'PaymentMethod', id: string, type?: Types.PaymentMethodType | null, label?: string | null, card?: { __typename?: 'CardPaymentMethod', brand?: string | null, last4?: string | null } | null }> | null };

export const PaymentMethodPropsFragmentDoc = gql`
    fragment PaymentMethodProps on PaymentMethod {
  id
  type
  label
  card {
    brand
    last4
  }
}
    `;
export const ReservationPaymentMethodsPropsFragmentDoc = gql`
    fragment ReservationPaymentMethodsProps on ReservationPaymentMethods {
  types
  methods {
    ...PaymentMethodProps
  }
}
    ${PaymentMethodPropsFragmentDoc}`;