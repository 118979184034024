<div class="container" [class.half-horizontal-margin]="halfHorizontalMargin">
    <div class="inputs" *ngIf="(inputRequirements | gbValues)?.length">

        <div *ngFor="let requirement of inputRequirements | gbValues">

            <ion-item class="item gb-transparent"
                      button="true"
                      detail="false"
                      lines="none"
                      *ngIf="requirement.type === InputRequirementType.Vehicle"
                      (click)="searchInputSelected.emit(requirement.field_name)">
                <ion-icon slot="start" name="car-sport-outline" color="primary"></ion-icon>
<!--                <ion-spinner name="dots" duration="undefined" *ngIf="loadingVehicle === true; else vehicleLoaded"></ion-spinner>-->
<!--                <ng-template #vehicleLoaded>-->
                    <ion-label *ngIf="requirement.value as vehicle; else showDefaultVehicleLabel" class="ellipsis">
                        {{vehicle.year}} {{vehicle.make}} {{vehicle.model}} - {{vehicle.license_plate_state}} - {{vehicle.license_plate | uppercase}}
                    </ion-label>
                    <ng-template #showDefaultVehicleLabel>
                        <ion-label color="primary">
                            {{requirement.label ?? 'Add a vehicle'}}
                        </ion-label>
                    </ng-template>
<!--                </ng-template>-->
            </ion-item>

            <ion-item class="item gb-transparent"
                      button="true"
                      detail="false"
                      (click)="searchInputSelected.emit(requirement.field_name)"
                      *ngIf="requirement.type === InputRequirementType.Location"
                      lines="none">
                <ion-icon slot="start" name="location" color="primary"></ion-icon>
<!--                <ion-spinner name="dots" duration="undefined" *ngIf="loadingLocation === true; else locationLoaded"></ion-spinner>-->
<!--                <ng-template #locationLoaded>-->
                    <ion-label *ngIf="requirement.value as location; else showDefault" class="ellipsis">
                        {{ location.address }}
                    </ion-label>
                    <ng-template #showDefault>
                        <ion-label color="primary">
                            {{ requirement.label ?? "Select Location" }}
                        </ion-label>
                    </ng-template>
<!--                </ng-template>-->
            </ion-item>
        </div>
    </div>
</div>
